<template>
  <div id="app">
    <div class="IE-warning">
      <div class="inner">
        <img src="/images/blocked-icon.png" alt="Stop Sign" aria-hidden="true">
        <div class="headline">Different web browser required</div>
        <strong>Warning</strong>: This browser is no longer supported or patched by Microsoft and poses a security risk. <br /> To access AuthAI and submit requests, please use: <em>Google Chrome</em>, <em>Microsoft Edge</em> or <em>Mozilla Firefox</em>.
      </div>
    </div>
    
    <Navigation v-if="showNavigation" id="primaryNav" />
    <router-view id="appContent" role="main" />
    <SessionExpires v-if="expiringToken" />
    <NewVersionMessage />
    <RotateDevice />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import Navigation from '@/components/Navigation.vue'
  import SessionExpires from '@/components/Modal.AuthSessionExpires.vue'
  import NewVersionMessage from '@/components/Modal.NewVersionMessage.vue'
  import RotateDevice from '@/components/RotateDevice.vue'
  export default {
    components: {
      Navigation,
      SessionExpires,
      NewVersionMessage,
      RotateDevice,
    },
    computed: {
      ...mapState({
        timerInterval: (state) => state.auth.timerInterval,
        tokenExpires: (state) => state.auth.tokenExpires,
        authenticated: (state) => state.auth.authenticated,
      }),
      ...mapGetters(['requiresOrganizationSelection']),
      showNavigation: function () {
        return this.authenticated && !this.requiresOrganizationSelection
      },
      expiringToken: function () {
        return this.authenticated && this.tokenExpires < 180
      },
    },
    watch: {
      authenticated() {
        if (this.authenticated) {
          this.checkSessionExpires()
          this.$store.dispatch('socketConnection/connectToSocket')
        }
      },
    },
    mounted: function () {
      if (this.authenticated) {
        this.checkSessionExpires()
        this.$store.dispatch('socketConnection/connectToSocket')
      }
    },
    methods: {
      checkSessionExpires: function () {
        if (this.authenticated) {
          setTimeout(() => {
            this.$store.dispatch('auth/getTokenExpiresTime')
            this.checkSessionExpires()
          }, this.timerInterval)
        }
      },
    },
  }
</script>

<style lang="scss"></style>
