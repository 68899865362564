import APIservice from '@/api/APIservice'

class uploadQueue {
  constructor() {
    this.queue = []
    this.concurrency = 2
    this.running = 0
  }

  canUpload() {
    return this.running < this.concurrency
  }

  upload(queueEntry) {
    this.running++ 

    const url = queueEntry.sendToPayer
    ? `BlobDocuments/UploadDocumentToSubmitted`
    : `BlobDocuments/AddBlobDocument`

    APIservice.UPLOAD(url, queueEntry.data, queueEntry.onProgressUpdate)
    .then((res) => {
      this.running--
      this.tryDequeue()
      queueEntry.success(res)
    })
    .catch((err) => {
      this.running--
      this.tryDequeue()
      queueEntry.fail(err)
    })
  }

  enqueue(queueEntry) {
    if (this.canUpload()) {
      this.upload(queueEntry)
    } else {
      this.queue.push(queueEntry)
    }
  }

  tryDequeue() {
    if (!this.canUpload()) {
      return
    }

    const queueEntry = this.queue.shift()
    if (queueEntry) {
      this.upload(queueEntry)
    }
  }
}

export default new uploadQueue()