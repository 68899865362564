<template>
  <div class="overlayContainer warnContainer">
    <focus-trap :active="true" :initial-focus="() => $refs.extendSessionButton">
      <div class="modal-inner warn-modal">
        <i class="far fa-exclamation-triangle"></i>
        <h5>Inactivity Warning</h5>
        <p>
          Since you've been inactive for a while, you will be automatically
          logged out in {{ tokenExpires }}s.
        </p>
        <div class="buttonWrap">
          <button ref="extendSessionButton" @click="extendSession">
            Continue Session
          </button>
          <a href="#" @click.prevent="signOut">Sign Out</a>
        </div>
      </div>
    </focus-trap>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { FocusTrap } from 'focus-trap-vue'

  export default {
    name: 'SessionExpires',
    components: { FocusTrap },
    data: function () {
      return {}
    },
    computed: {
      ...mapState({
        tokenExpires: (state) => state.auth.tokenExpires,
      }),
    },
    mounted() {
      this.$logger.logShowSessionExpiringModal();
    },
    methods: {
      extendSession: function () {
        this.$logger.logContinueSession()
        this.$store.dispatch('auth/refreshAuthToken')
      },
      signOut: function () {
        this.$logger.logSignOut(true)
        this.$store.dispatch('RESET_GLOBAL_STATE')
      },
    },
  }
</script>
