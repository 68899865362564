import APIservice from '@/api/APIservice'
import router from '@/router'
import userRoles from '@/shared/userRoles'
import logger from '@/shared/logger'
import moment from 'moment'
import jwtDecode from 'jwt-decode'

function getTwoFactorMethodsFromSessionStorage() {
  let json = sessionStorage.getItem('twoFactorMethods')
  if (!json) {
    return null
  }

  return JSON.parse(json)
}

function getUserRolesFromSessionStorage() {
  let json = sessionStorage.getItem('userRoles')
  if (!json) {
    return []
  }

  return JSON.parse(json)
}

function getRolesFromToken(jwt) {
  const roles =
    jwt['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']

  if (!roles) {
    return []
  }

  if (Array.isArray(roles)) {
    return roles
  }

  return [roles]
}

function initialState() {
  return {
    maintenance: false,
    loggedIn: false,
    authenticated: false,
    twoFactorRequired: false,
    twoFactorEnabled: false,
    twoFactorVerified: false,
    twoFactorMethods: [],
    roles: [],
    userGuid: null,
    timerInterval: 30000,
    tokenExpires: 3600,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async checkMaintenanceMode({ commit }) {
      await APIservice.GET('InMaintenanceMode')
        .then((res) => {
          commit('setMaitenance', res.data)
        })
    },
    async authenticateUser({ commit, dispatch }, params) {
      await APIservice.POST(`Auth/CreateToken`, params)
        .then((res) => {
          commit('setLoggedIn', true)
          dispatch('setUserSession', res.data)
          router.push('/')
        })
        .catch((err) => {
          if(err.response?.status === 503) {
            return Promise.reject(new Error('503'))
          }
          return Promise.reject(new Error('auth'))
        })
    },
    setUserSession({ commit }, params) {
      sessionStorage.setItem('token', params.token)
      sessionStorage.setItem('expiration', params.expiration)
      sessionStorage.setItem('customerId', params.customerId)

      const jwt = jwtDecode(params.token)
      commit('setRoles', getRolesFromToken(jwt))
      commit('setUserGuid', jwt['name'])
      commit('setTwoFactorRequired', params.twoFactorRequired ?? false)
      commit('setTwoFactorEnabled', params.twoFactorEnabled ?? false)
      commit('setTwoFactorVerified', params.twoFactorVerified ?? false)
      commit('setTwoFactorMethods', params.twoFactorMethods ?? '')
      commit(
        'setAuthenticated',
        params.twoFactorVerified ||
          (!params.twoFactorEnabled && !params.twoFactorRequired)
      )

      commit('setTimerInvertval', 30000)
    },
    checkSessionToken: function ({ commit, dispatch }) {
      const token = sessionStorage.getItem('token')
      const expires = sessionStorage.getItem('expiration')
      const twoFactorRequired =
        sessionStorage.getItem('twoFactorRequired') === 'true'
      const twoFactorEnabled =
        sessionStorage.getItem('twoFactorEnabled') === 'true'
      const twoFactorVerified =
        sessionStorage.getItem('twoFactorVerified') === 'true'
      const twoFactorMethods = getTwoFactorMethodsFromSessionStorage()
      const roles = getUserRolesFromSessionStorage()
      const userGuid = sessionStorage.getItem('userGuid')

      if (token && moment().utc().format() < expires) {
        commit('setLoggedIn', true)
        commit(
          'setAuthenticated',
          twoFactorVerified || (!twoFactorEnabled && !twoFactorRequired)
        )
        commit('setTwoFactorMethods', twoFactorMethods)
        commit('setRoles', roles)
        commit('setUserGuid', userGuid)
      } else {
        dispatch('signOut')
      }
    },
    getTokenExpiresTime({ commit, dispatch }) {
      dispatch('checkSessionToken')

      const expires = sessionStorage.getItem('expiration')
      const diff = moment(expires).diff(moment(moment().utc()))
      const secsToExpire = Math.floor(diff * 0.001)
      if (secsToExpire < 180) {
        commit('setTimerInvertval', 1000)
      }
      commit('setTokenExpires', secsToExpire)
    },
    async refreshAuthToken({ dispatch }) {
      await APIservice.POST(`Auth/UpdateTokenForLoggedInUser`, {}, true)
        .then((res) => {
          dispatch('setUserSession', res.data)
        })
        .catch((err) => {
          // dispatch('signOut')
        })
    },
    async verifyTwoFactorEmail({ dispatch }, params) {
      return await APIservice.POST(`Auth/VerifyTwoFactorEmail`, params)
        .then((res) => {
          dispatch('setUserSession', res.data)
          router.push('/')
        })
        .catch(() => false)
    },
    async verifyTwoFactorSms({ dispatch }, params) {
      return await APIservice.POST(`Auth/VerifyTwoFactorSms`, params)
        .then((res) => {
          dispatch('setUserSession', res.data)
          router.push('/')
        })
        .catch(() => false)
    },
    async verifyTwoFactorAuthApp({ dispatch }, params) {
      return await APIservice.POST(`Auth/VerifyAuthenticatorApp`, params)
        .then((res) => {
          dispatch('setUserSession', res.data)
          router.push('/')
        })
        .catch(() => false)
    },
    signOut({ dispatch, state }) {
      if (state.loggedIn) {
        logger.logSignOut()
        dispatch('RESET_GLOBAL_STATE', null, { root: true })
      }
    },
  },
  mutations: {
    setMaitenance(state, data) {
      state.maintenance = data
    },
    setLoggedIn(state, bool) {
      state.loggedIn = bool
    },
    setAuthenticated(state, bool) {
      state.authenticated = bool
    },
    setTwoFactorRequired(state, bool) {
      state.twoFactorRequired = bool
      sessionStorage.setItem('twoFactorRequired', bool)
    },
    setTwoFactorEnabled(state, bool) {
      state.twoFactorEnabled = bool
      sessionStorage.setItem('twoFactorEnabled', bool)
    },
    setTwoFactorVerified(state, bool) {
      state.twoFactorVerified = bool
      sessionStorage.setItem('twoFactorVerified', bool)
    },
    setTwoFactorMethods(state, methods) {
      state.twoFactorMethods = methods
      sessionStorage.setItem('twoFactorMethods', JSON.stringify(methods))
    },
    setRoles(state, roles) {
      state.roles = roles
      sessionStorage.setItem('userRoles', JSON.stringify(roles))
    },
    setUserGuid(state, guid) {
      state.userGuid = guid
      sessionStorage.setItem('userGuid', guid)
    },
    setTimerInvertval: (state, time) => {
      state.timerInterval = time
    },
    setTokenExpires: (state, seconds) => {
      state.tokenExpires = seconds
    },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
  getters: {
    twoFactorMethods: (state) => {
      let result = {
        email: null,
        text: null,
        authApp: null,
      }

      let methods = state.twoFactorMethods

      if (!methods || !methods.length) {
        return result
      }

      result.email = methods.find((m) => m.communicationMethod === 'Email')
      result.text = methods.find((m) => m.communicationMethod === 'Text')
      result.authApp = methods.find(
        (m) => m.communicationMethod === 'Authenticator App'
      )

      return result
    },
    verifiedTwoFactorMethods: (state) => {
      let methods = state.twoFactorMethods
      if (!methods) {
        return []
      }

      return methods.filter((m) => m.verified)
    },
    isOliveSupport: (state) => {
      return state.roles.includes(userRoles.oliveSupport)
    },
    isCustomerServiceManager: (state) => {
      return state.roles.includes(userRoles.customerServiceManager)
    },
  },
}
