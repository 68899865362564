import APIservice from '@/api/APIservice'

function initialState() {
  return {
    isSearching: false,
    hasSearched: false,
    searchText: '',
    paRequestList: false,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async searchPaRequests({ commit, dispatch, state, rootGetters }) {
      if (state.isSearching) {
        return
      }

      commit('searchStart')

      if (state.searchText.indexOf(' ') === -1) {
        // Before searching lookup as a PA ID.
        var pa = await dispatch('supportLookup')
        if (pa) {
          commit('clearState')
          return pa
        }
      }

      const req = {
        phrase: state.searchText,
        maxHits: 50,
      }

      await APIservice.POST(`PaRequests/SearchPaRequests`, req).then((res) => {
        commit('setPaRequestList', res.data)
      })

      commit('searchComplete')
    },
    async supportLookup({ state }) {
      return await APIservice.GET(
        `PaRequests/FindPaRequestById/${state.searchText}`
      ).then((res) => {
        return res.data
      }).catch(() => false)
    },
  },
  mutations: {
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
    setPaRequestList(state, data) {
      state.paRequestList = data
    },
    searchStart(state) {
      state.isSearching = true
      state.hasSearched = true
    },
    searchComplete(state) {
      state.isSearching = false
    },
    setSearchText(state, data) {
      state.searchText = data
    },
  },
}
