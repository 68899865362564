import VueAppInsights from 'vue-application-insights'
import ENV from '@/config/environment'
import router from '@/router'
import logger from '@/shared/logger'

export default {
  install: (Vue) => {
    try {
      if (ENV.APPINSIGHTS) {
        Vue.use(VueAppInsights, {
          baseName: ENV.NAME,
          appInsightsConfig: {
            connectionString: ENV.APPINSIGHTS,
          },
          router,
        })
        Vue.appInsights.addTelemetryInitializer(logger.telemetryInitializer)
      }
    } catch (err) {
      console.warn(err)
    }

    Vue.prototype.$logger = logger
  }
}