import APIservice from '@/api/APIservice'

function initialState() {
  return {
    overview: false,
    organizationProviderList: false,
    recentlyUsedProviders: false,
    userSearchFilters: false,
    payer: {
      name: 'Florida Blue',
      initials: 'FB',
    },
    statusSteps: [
      {
        id: 1,
        title: 'Sending To Payer',
        finalText: 'Submitted',
        finalTextAlt: 'Submitted',
      },
      {
        id: 2,
        title: 'Received By Payer',
        finalText: 'Reference Number Assigned',
        finalTextAlt: 'Acknowledged By Payer',
      },
      {
        id: 3,
        title: 'Payer Review',
        finalText: 'Finished',
        finalTextAlt: 'Finished',
      },
      {
        id: 4,
        title: 'Decision From Payer',
        finalText: 'Complete',
        finalTextAlt: 'Complete',
      },
    ],
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    getOverview({ commit }) {
      APIservice.GET('Overview/GetOverview')
        .then((res) => {
          commit('setOverview', res.data)
          commit('setUserSearchFilters', res.data.searchFilters)
        })
        .catch((err) => console.log(err))
    },
    getRecentProviders({ commit }) {
      APIservice.GET(`Providers/GetMyRecentProviders`)
        .then((res) => commit('setRecentlyUsedProviders', res.data))
        .catch((err) => console.log(err))
    },
    getOrganizationProviders({ commit }) {
      APIservice.GET('Providers/GetProvidersForMyOrganization')
        .then((res) => commit('setOrganizationProviders', res.data))
        .catch((err) => console.log(err))
    },
    getUserSearchFilters({ commit }) {
      APIservice.GET(`SearchFilter/GetSearchFiltersForUser`).then((res) =>
        commit('setUserSearchFilters', res.data)
      )
    },
    addProviderToMyFavorites({ commit }, providerId) {
      if (!providerId) {
        return
      }
      let params = {
        providerId: providerId,
        ranking: 0
      }
      APIservice.PUT(`Providers/AddProviderToMyFavorites`, params)
        .then((res) =>  commit('setRecentlyUsedProviders', res.data))
        .catch((err) => console.log(err))
    },
    async createUserSearchFilter({ commit }, params) {
      await APIservice.POST(`SearchFilter/CreateSearchFilter`, params)
        .then((res) => {
          console.log(res)
          sessionStorage.setItem('worklistActiveFilter', res.data[res.data.length -1].searchFilterId)
          commit('setUserSearchFilters', res.data)
        })
        .catch(() => {
          commit('setUserSearchFilters', [])
        })
    },
    async updateUserSearchFilter({ commit }, params) {
      await APIservice.PUT(`SearchFilter/UpdateSearchFilter/${params.searchFilterId}`, params.data)
        .then((res) => {
          console.log(res)
          commit('setUserSearchFilters', res.data)
        })
        .catch(() => {
          commit('setUserSearchFilters', [])
        })
    },
    async deleteUserSearchFilter({ commit }, searchFilterId) {
      await APIservice.DELETE(`SearchFilter/DeleteSearchFilter/${searchFilterId}`)
        .then((res) => {
          let sessionFilterId = sessionStorage.getItem('worklistActiveFilter')
          if (searchFilterId == sessionFilterId){
            sessionStorage.removeItem('worklistActiveFilter')
          }
          commit('setUserSearchFilters', res.data)
        })
        .catch(() => {
          commit('setUserSearchFilters', [])
        })
    },
  },
  mutations: {
    setOverview(state, data) {
      state.overview = data
    },
    setRecentlyUsedProviders(state, data) {
      state.recentlyUsedProviders = data
    },
    setOrganizationProviders(state, data) {
      state.organizationProviderList = data
    },
    setUserSearchFilters(state, data) {
      state.userSearchFilters = data
    },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
  getters: {
    userSearchFiltersForSelect(state) {
      return state.userSearchFilters.filter((f) => f.filterType !== 1)
    },
    recentFacilityByOccurrence: (state) => {
      if (!state.recentlyUsedProviders?.recentlyUsedFacilities?.length){
        return false
      }

      return [...state.recentlyUsedProviders.recentlyUsedFacilities]
        .sort((a, b) => {
          return a.usedCount > b.usedCount
            ? -1
            : a.usedCount < b.usedCount
            ? 1
            : 0
        })
        .map((f) => {
          f.facility.recentUsed = true
          return Object.freeze(f.facility)
        })
    },
    recentProviderByOccurrence: (state) => {
      if (!state.recentlyUsedProviders?.recentlyUsedProviders?.length) {
        return false
      }

      let sorted = [...state.recentlyUsedProviders.recentlyUsedProviders]
        .sort((a, b) => {
          return a.usedCount > b.usedCount
            ? -1
            : a.usedCount < b.usedCount
            ? 1
            : 0
        })
        .map((p) => {
          p.provider.recentUsed = true
          return Object.freeze(p.provider)
        })

      if (state.recentlyUsedProviders.currentSSOProviders) {
        sorted = sorted.concat(state.recentlyUsedProviders.currentSSOProviders)
          .filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.providerId === value.providerId
            ))
          )
      }
      return sorted
    },
    recentFacilityIdArray: (state) => {
      if (!state.recentlyUsedProviders?.recentlyUsedFacilities?.length) {
        return []
      }
      return state.recentlyUsedProviders.recentlyUsedFacilities.map(f => f.facility.facilityId)
    },
    recentProviderIdArray: (state) => {
      if (!state.recentlyUsedProviders?.recentlyUsedProviders?.length) {
        return []
      }
      return state.recentlyUsedProviders.recentlyUsedProviders.map(p => p.provider.providerId)
    },
    favoriteProviderIdArray: (state) => {
      if (!state.recentlyUsedProviders?.favoriteProviders?.length) {
        return []
      }
      return state.recentlyUsedProviders.favoriteProviders.map(p => p.providerId)
    },
    statusGroups: (state) => {
      if (!state.overview) {
        return false
      }
      // return {
      //   draft: [0],
      //   pending: [70, 80, 100, 700],
      //   response: [11, 200, 210, 500, 705, 710, 900],
      // }
      return {
        draft: state.overview.priorAuthStatuses
          .filter((s) => s.isDraftStatus)
          .map((s) => s.priorAuthStatusId),
        pending: state.overview.priorAuthStatuses
          .filter((s) => s.isPendingStatus)
          .map((s) => s.priorAuthStatusId),
        response: state.overview.priorAuthStatuses
          .filter((s) => s.isFinalStatus)
          .map((s) => s.priorAuthStatusId),
      }
    },
  },
}
