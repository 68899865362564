import APIservice from "@/api/APIservice"
import utilities from "@/shared/utilities"
import logger from '@/shared/logger'

function initialState() {
  return {
    allEntryLinesComplete: false,
    completedEntryLines: [],
    analyzingDocuments: true,
    analyzedDocumentsResult: null,
  }
}

function getDocumentAnalysisProgress(oliveTrackingId) {
  return APIservice.GET(`Attestation/GetDocumentAnalysisProgress/${oliveTrackingId}`)
    .then((res) => {
      return res
    }).catch((err) => {
      return err
    })
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    updateAllEntryLinesComplete({ commit }, bool) {
      commit('setAllEntryLinesComplete', bool)
    },
    updateCompletedEntryLines({ commit }, completedEntryLines) {
      commit('setCompletedEntryLines', completedEntryLines)
    },
    async checkDocumentAnalysisProgress({ commit }, params) {
      const startTime = new Date().getTime()

      let response = await getDocumentAnalysisProgress(params.oliveTrackingId)
      
      if (response?.data?.isDocumentAnalysisDone === false) {
        let checkTime = (response?.data?.checkInSeconds || 5) * 1000
        let validate = result => result?.data?.isDocumentAnalysisDone === false
        await utilities.poll(() => getDocumentAnalysisProgress(params.oliveTrackingId), validate, checkTime)
      }

      await APIservice.GET(`Attestation/GetAttestationProgress/${params.paRequestId}`)
        .then(res => {
          commit('setAnalyzedDocumentsResult', res.data)
        })
        .catch(() => {          
          commit('setAnalyzedDocumentsResult', null)
        })

      commit('setAnalyzingDocuments', false)

      const endTime = new Date().getTime()
      logger.logTimeWaitingForDocumentsAnalyzed(endTime - startTime)
    },
    resetAnalyzingDocumentsState({ commit }) {
      commit('setAnalyzingDocuments', true)
      commit('setAnalyzedDocumentsResult', null)
    },
  },
  mutations: {
    setAllEntryLinesComplete(state, allEntryLinesComplete) {
      state.allEntryLinesComplete = allEntryLinesComplete
    },
    setCompletedEntryLines(state, completed) {
      state.completedEntryLines = completed
    },
    setAnalyzingDocuments(state, analyzingDocuments) {
      state.analyzingDocuments = analyzingDocuments
    },
    setAnalyzedDocumentsResult(state, analyzedDocumentsResult) {
      state.analyzedDocumentsResult = analyzedDocumentsResult
    },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
}
