// import paRequests from '@/api/paRequests'
import APIservice from '@/api/APIservice'
import router from '@/router'
import logger from '@/shared/logger'
import utilities from '@/shared/utilities'

function initialState() {
  return {
    creationStep: 0,
    draftPaState: false,
    savingDraft: false,
    recentlySavedDraft: false,
    recentlySavedTimerId: null,
    showSubmissionWarning: false,
    highlightRequiredFields: false,
    draftValidity: false,
    requestEntryLines: false,
    requestCptCodeWithNoAttestation: false,
    requestCptCodeWithAttestationAndAi: false,
    isSubmitting: false,
    isCheckingEli: false,
    createPaModel: {
      paRequestId: 0,
      patientId: null,
      insuredPartyId: 0,
      inPatient: false,
      urgency: 0,
      orderingProviderId: null,
      servicingProviderId: null,
      requestingProviderId: null,
      attendingProviderId: null,
      referralId: 0,
      facilityId: null,
      primaryDiagnosis: '',
      primaryDiagnosisId: 0,
      diagnosisCodeIds: [],
      stempCodes: [],
      serviceTypeCodeId: 0,
      placeOfServiceId: 0,
      admissionDate: null,
      lengthOfStay: 1,
    },
    updateRequestId: 0,
    uiMessages: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async getDraftPaRequestByOliveTrackingId({ commit }, oliveTrackingId) {
      await APIservice.GET(
        `DraftPaRequests/GetDraftPaRequest/${oliveTrackingId}`
      )
        .then((res) => {
          commit('setDraftPaState', res.data)
        })
        .catch(() => {
          commit('setDraftPaState', false)
        })
    },
    refreshDraftPatientEligibility({ commit, state }, patientId) {
      commit('setCheckingEli', true)
      APIservice.GET(
        `/DraftPatients/RefreshDraftPatientEligibility/${patientId}`
      ).then((res) => {
        if (state.draftPaState) {
          let patient = { ...res.data }
          let updatedDraft = { ...state.draftPaState,  patient: patient }
          commit('setDraftPaState', updatedDraft)
          commit('setCheckingEli', false)
        }
      }).catch(() => {
        commit('setCheckingEli', false)
      })
    },
    resumeDraftPaRequest({ state, commit }, draftParequest) {
      let updatedCreateModel = { ...state.createPaModel }

      Object.keys(draftParequest)
        .filter((key) => key in updatedCreateModel)
        .forEach((key) => {
          updatedCreateModel[key] = draftParequest[key]
        })

      commit('updateCreatePaModel', updatedCreateModel)
      // commit('setCreationStep', 1)
    },
    async createDraftPaRequest({ state, commit, dispatch }) {
      await APIservice.POST(
        `DraftPaRequests/AddDraftPaRequest`,
        state.createPaModel
      ).then((res) => {
        dispatch('resumeDraftPaRequest', res.data)
        commit('document/setDraftDocuments', [], { root: true })
        commit('setDraftPaState', res.data)
        commit('setCreationStep', 1)
        router.push({
          name: 'EditDraftPaRequest',
          params: {
            oliveTrackingId: res.data.oliveTrackingId,
          },
        })
      })
    },
    async updateDraftPaRequest({ state, commit, dispatch }, data) {
      const saving = state.savingDraft
      const updateId = state.updateRequestId
        
      if (data) {
        commit('updateCreatePaModel', data)
      }

      if(saving) {
        commit('incrementUpdateRequestId')
        return
      }

      commit('setSavingDraftFlag', true)
      let res = await APIservice.POST(`DraftPaRequests/UpdateDraftPaRequest`, state.createPaModel)
      commit('setSavingDraftFlag', false)

      if (state.updateRequestId != updateId) {
        dispatch('updateDraftPaRequest', false)
        return
      }
      
      dispatch('draftPaRequestUpdated', { draftPaState: res.data })
    },
    async bulkAddProcedures({ state, commit, dispatch }, rawText) {
      commit('setSavingDraftFlag', true)
      return await APIservice.POST(
        `DraftPaRequests/BulkUpdateCptCodesOnDraftPaRequest`,
        {
          paRequestId: state.createPaModel.paRequestId,
          data: rawText,
        }
      ).then((res) => {
        // Only update draft and show the updated message if any procedures were added.
        if (state.draftPaState?.requestedStemp?.length !== res.data?.requestedStemp?.length)
        {
          dispatch('draftPaRequestUpdated', { 
            draftPaState: res.data,
          })
          return true
        } else {
          commit('setSavingDraftFlag', false)
        }
      })
    },
    async bulkAddDiagnosisCodes({ state, commit, dispatch }, rawText) {
      commit('setSavingDraftFlag', true)
      return await APIservice.POST(
        `DraftPaRequests/BulkUpdateIcdCodesOnDraftPaRequest`,
        {
          paRequestId: state.createPaModel.paRequestId,
          data: rawText,
        }
      ).then((res) => {
        // Only update draft and show the updated message if any diagnosis codes were added.
        if (state.draftPaState?.diagnosisCodes?.length !== res.data?.diagnosisCodes?.length)
        {
          dispatch('draftPaRequestUpdated', { 
            draftPaState: res.data,
          })
          return true
        } else {
          commit('setSavingDraftFlag', false)
        }      
      })
    },
    async draftPaRequestUpdated({ state, dispatch, commit }, params) {
      dispatch('auth/refreshAuthToken', null, { root: true })
      dispatch('resumeDraftPaRequest', params.draftPaState)
      commit('setDraftPaState', params.draftPaState)
      
      if (state.highlightRequiredFields) { 
        await dispatch('checkIfDraftIsValid')
      }
      
      commit('setSavingDraftFlag', false)
      clearTimeout(state.recentlySavedTimerId)
      commit(
        'setRecentlySavedTimerId',
        setTimeout(() => commit('setRecentlySavedDraft', false), 3000)
      )
      commit('setRecentlySavedDraft', true)
    },
    checkForSubmissionErrors({ getters, commit }) {
      if (getters.missingRequiredProps || getters.missingProcedureProps) {
        commit('setShowSubmissionWarning', true)
        commit('setHighlightRequiredFields', true)
        logger.logMissingRequirementsWarning()
        return
      }
    },
    async checkIfDraftIsValid({ state, commit }) {
      await APIservice.GET(`DraftPaRequests/IsValidDraft/${state.createPaModel.paRequestId}`)
        .then((res) => {
          commit('setDraftValidity', res.data)
        })
    },
    async preDocumentsCheck({ state, commit }) {
      await APIservice.GET(`DraftPaRequests/PreDocumentChecks/${state.createPaModel.paRequestId}`)
        .then((res) => {
          commit('setDraftValidity', res.data.isValidResult)
          if (res.data.attestationAnswerEntries) {
            commit('setRequestEntryLines', [...res.data.attestationAnswerEntries])
          }
          if (res.data.cptCodeWithNoAttestation) {
            commit('setRequestCptCodeWithNoAttestation', res.data.cptCodeWithNoAttestation)
          }
          if (res.data.cptCodeWithAttestationAndAi) {
            commit('setRequestCptCodeWithAttestationAndAi', res.data.cptCodeWithAttestationAndAi)
          }
        })
    },
    async submitPaRequest({ commit, dispatch, state }, params) {
      commit('setShowSubmissionWarning', false)
      commit('setHighlightRequiredFields', false)
      commit('setIsSubmitting', true)

      await APIservice.POST(
        `DraftPaRequests/SubmitDraftPaRequest/${params.paRequestId}`,
        {}
      ).then(() => {
        dispatch('staticData/getRecentProviders', null, { root: true })
        router.push({
          name: 'DetailPaRequest',
          params: {
            oliveTrackingId: params.oliveTrackingId,
          },
        })
      }).catch(async () => {
        // If we fail to submit the draft get the current state from the server
        await dispatch('getDraftPaRequestByOliveTrackingId', params.oliveTrackingId)
        dispatch('resumeDraftPaRequest', state.draftPaState)
        commit('setCreationStep', 1)
        commit('setIsSubmitting', false)
      })
    },
  },
  mutations: {
    setDraftPaState(state, data) {
      state.draftPaState = data
    },
    setCreationStep(state, step) {
      state.creationStep = step
    },
    incrementUpdateRequestId(state) {
      state.updateRequestId++
    },
    updateCreatePaModel(state, data) {
      state.createPaModel = Object.assign({}, state.createPaModel, data)
      // state.creationStep = state.creationStep + 1
    },
    setSavingDraftFlag(state, data) {
      state.savingDraft = data
    },
    setShowSubmissionWarning(state, data) {
      state.showSubmissionWarning = data
    },
    setHighlightRequiredFields(state, data) {
      state.highlightRequiredFields = data
    },
    setDraftValidity(state, data) {
      state.draftValidity = data
    },
    setIsSubmitting(state, data) {
      state.isSubmitting = data
    },
    setRecentlySavedDraft(state, data) {
      state.recentlySavedDraft = data
    },
    setRecentlySavedTimerId(state, data) {
      state.recentlySavedTimerId = data
    },
    setRequestEntryLines(state, data) {
      state.requestEntryLines = data
    },
    setRequestCptCodeWithNoAttestation(state, data) {
      state.requestCptCodeWithNoAttestation = data
    },
    setRequestCptCodeWithAttestationAndAi(state, data) {
      state.requestCptCodeWithAttestationAndAi = data
    },
    setCheckingEli(state, bool) {
      state.isCheckingEli = bool
    },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
  getters: {
    invalidProperties(state) {      
      const propertyNameMap = {
        'DiagnosisCode.DiagnosisCodeListId': 'diagnosisCodes'
      }

      if (!state.draftValidity?.inValidProperties?.length) {
        return false
      }

      const invalidProperties = []
      state.draftValidity.inValidProperties.forEach(p => {
        let propName = p.propertyName
        const mapped = propertyNameMap[propName]
        if (mapped) {
          propName = mapped
        }

        if (propName.includes('.')) {
          propName = propName.slice(propName.lastIndexOf('.') + 1)
        }

        propName = utilities.convertPascalToCamelCase(propName)

        invalidProperties.push({
          propertyName: propName,
          id: p.id,
          idPropertyName: p.idPropertyName,
          conditionFailedDisplayMessage: p.conditionFailedDisplayMessage,         
        })
      })

      if (invalidProperties.length) {
        return invalidProperties
      }
      return false
    },
    missingRequiredProps(state, getters) {
      if (!getters.invalidProperties?.length) {
        return false
      }

      const missing = getters.invalidProperties
        .filter(p => !p.id)
        .map(p => {
          return { 
            message: p.conditionFailedDisplayMessage, 
            propertyName: p.propertyName
          }
        })

      if (missing.length) {
        return missing
      }
      return false
    },
    missingProcedureProps(state, getters) {
      if (!getters.invalidProperties?.length) {
        return false
      }

      const missing = getters.invalidProperties
        .filter(p => p.id && p.idPropertyName === 'RequestEntryLineId')
        
      const grouped = {}

      missing.forEach(p => {
        const entryLineId = p.id
        const entryLine = grouped[entryLineId]
        const propertyName = utilities.convertPascalToCamelCase(p.propertyName)
        if (!entryLine) {
          const stemp = state.draftPaState.requestedStemp
            .find(s => s.requestEntryLineId === entryLineId)?.stempCode.code
          grouped[entryLineId] = {
            requestEntryLineId: entryLineId,
            code: stemp,
            missing: [{message: p.conditionFailedDisplayMessage, propertyName: propertyName}],
          }
        } else {
          entryLine.missing.push({ message: p.conditionFailedDisplayMessage, propertyName: propertyName })
        }
      })

      if (missing.length) {
        return Object.values(grouped)
      }
      return false
    },
  },
}
