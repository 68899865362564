import logger from '@/shared/logger'

function initialState() {
  return {
    from: null,
    lastNavigationTime: null,
    recentSubmissions: [],
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    onNavigation({ state, commit }, params) {
      const previousNavigationTime = state.lastNavigationTime
      const currentNavigationTime = new Date().getTime()
      commit('setFrom', params.from)
      commit('setLastNavigationTime', currentNavigationTime)

      const timeOnPreviousView = previousNavigationTime
        ? currentNavigationTime - previousNavigationTime
        : null

      logger.logNavigation(
        params.from?.name,
        params.to?.name,
        timeOnPreviousView
      )
    },
    pushRecentSubmission({ state, commit }, data) {
      const submissions = state.recentSubmissions
        .filter(s => s.oliveTrackingId !== data.submission.oliveTrackingId)

      submissions.unshift({
        oliveTrackingId: data.submission.oliveTrackingId,
        patientName: data.submission.patient.name,
        isDraft: data.isDraft,
      })

      commit('setRecentSubmissions', submissions)
    },
  },
  mutations: {
    setFrom(state, data) {
      state.from = data
    },
    setLastNavigationTime(state, data) {
      state.lastNavigationTime = data
    },
    setRecentSubmissions(state, data) {
      state.recentSubmissions = data
    },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
}
