<template>
  <div>
    <nav role="navigation">
      <h1 class="logo">
        <img src="/images/availity_logo.svg" alt="Availity AuthAI"/>
      </h1>
      <router-link
        v-if="canCreateSubmissions"
        :to="{ name: 'MemberLookup' }"
        tag="button"
        class="router-btn new-submission-btn">
        New Submission
      </router-link>
      <router-link 
        v-if="canCreateSubmissions"
        :to="{ name: 'Dashboard' }">
        <i class="far fa-bell" />
        <span>Dashboard</span>
      </router-link>
      <router-link v-if="canCreateSubmissions" :to="{ name: 'ListPaRequest' }">
        <i class="far fa-folders" />
        <span>Submissions</span>
      </router-link>
      <router-link
        v-if="canCreateSubmissions"
        :to="{ name: 'Search' }"
        @click.native="search">
        <i class="far fa-search" />
        <span>Search</span>
      </router-link>
      <!-- <router-link :to="{ name: 'Settings' }">
        <i class="far fa-cog" />
        <span>Configuration</span>
      </router-link> -->
      <router-link :to="{ name: 'HelpCenter' }">
        <i class="far fa-info-circle" />
        <span>Help Center</span>
      </router-link>
      <router-link :to="{ name: 'MyAccount' }">
        <i class="far fa-user" />
        <span>My Account</span>
      </router-link>
      <router-link v-if="canRunReports" :to="{ name: 'Reports' }">
        <i class="far fa-chart-bar" />
        <span>Reporting</span>
      </router-link>
      <a href="#" @click.prevent="signOut()">
        <i class="far fa-portal-exit"></i>
        <span>Sign Out</span>
      </a>
    </nav>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'Navigation',
    components: {},
    computed: {
      ...mapGetters('auth', ['isCustomerServiceManager']),
      canCreateSubmissions() {
        return !this.isCustomerServiceManager
      },
      canRunReports() {
        return this.isCustomerServiceManager
      },
    },
    methods: {
      signOut: function () {
        this.$logger.logSignOut(true)
        this.$store.dispatch('RESET_GLOBAL_STATE')
      },
      search: function () {
        // when navigating to the search page via the navigation link automatically clear the search state
        this.$store.commit('searchPa/clearState')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .devInfo {
    margin: 1rem;
  }
  .new-submission-btn {
    display: block;
    margin: $spacer-2x auto;
    font-weight: 400;
    font-size: 1.6rem;
  }
</style>
