const ENV = (function () {
  let ENV = {}
  const host = window.location.hostname
  // full Availity app insights key
  // ENV.APPINSIGHTS = 'InstrumentationKey=189874c9-2ce9-414a-90dd-acbec0fe6429;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/'
  ENV.APPINSIGHTS = 'InstrumentationKey=189874c9-2ce9-414a-90dd-acbec0fe6429;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/'
  switch (host) {
    
    // --------------- Production Env ------------------------------------------------------------//
    case 'flblue.azp.availity.com':
    case 'purple-meadow-0af001010.3.azurestaticapps.net':
      ENV.NAME = 'Production'
      ENV.API = `https://flblue.prod.um.azp.availity.com/api/`
      ENV.HOST = host
      ENV.attestation = {
        host: 'https://flblue.prod.attestation.azp.availity.com/',
        api: ENV.API,
      }
      break
    // --------------- Staging Env ------------------------------------------------------------//
    case 'flblue.stage.azs.availity.com':
    case 'nice-stone-068be570f.3.azurestaticapps.net':
      ENV.NAME = 'Staging'
      ENV.API = `https://flblue.stage.um.azs.availity.com/api/`
      ENV.HOST = host
      ENV.attestation = {
        host: 'https://salmon-sand-0ed8c560f-staging.eastus2.3.azurestaticapps.net',
        api: ENV.API,
      }
      break
    // --------------- Test Env ------------------------------------------------------------//
    case 'test.azn.availity.com':
    case 'flblue.test.azn.availity.com':
    case 'ambitious-river-00137470f.3.azurestaticapps.net':
      ENV.NAME = 'Test'
      ENV.API = `https://flblue.test.um.azn.availity.com/api/`
      ENV.HOST = host
      ENV.attestation = {
        host: 'https://salmon-sand-0ed8c560f-test.eastus2.3.azurestaticapps.net',
        api: ENV.API,
      }
      break
    // --------------- Dev & Local Env ------------------------------------------------------------//
    case 'dev.azn.availity.com':
    case 'flblue.dev.azn.availity.com':
    case 'blue-pebble-09a332c10.3.azurestaticapps.net':
      ENV.NAME = 'Development'
      ENV.API = `https://flblue.dev.um.azn.availity.com/api/`
      ENV.HOST = host
      ENV.attestation = {
        host: 'https://salmon-sand-0ed8c560f-development.eastus2.3.azurestaticapps.net',
        api: ENV.API,
      }
      break
    default:
      ENV.NAME = 'Local'
      if (API === 'local') ENV.API = `https://localhost:44311/api/`
      else ENV.API = `https://flblue.dev.um.azn.availity.com/api/`
      // ENV.attestationMFE = 'http://localhost:1337' //'https://red-meadow-01a902b10.1.azurestaticapps.net/asset-manifest.json' //'/mfe' //'http://localhost:1337'
      ENV.HOST = host
      ENV.attestation = {
        host: 'https://salmon-sand-0ed8c560f-development.eastus2.3.azurestaticapps.net',
        api: ENV.API,
      }
      break
  }
  return ENV
})()

export default ENV
