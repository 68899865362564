import APIservice from '@/api/APIservice'
import router from '@/router'
import utilities from '@/shared/utilities'

const includedDifferences = {
  InPatient: 'Type',
  ServiceTypeCodeId: 'Service Type',
  PlaceOfServiceId: 'Place of Service',
  AdmissionDate: 'Admission Date',
  LengthOfStay: 'Stay',
  RequestedStemp: 'Procedure(s) Requested',
  DiagnosisCodes: 'Diagnosis Codes',
  OrderingProvider: 'Ordering Provider',
  ServicingProvider: 'Rendering Provider',
  // AttendingProvider: 'Attending Provider',
  Facility: 'Facility',
}

function initialState() {
  return {
    paRequest: false,
    member: false,
    differences: false,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async getPaRequestByOliveTrackingId({ commit }, oliveTrackingId) {
      await APIservice.GET(`PaRequests/GetPaRequest/${oliveTrackingId}`).then(
        (res) => {
          commit('setMember', res.data.paRequest?.patient)
          commit('setPaRequestDetails', res.data.paRequest)
          commit('setDifferences', res.data.differences)
        }
      )
        .catch((err) => {
          if(err.response.status === 404) {
            router.replace('/Error404')
          }
        })
    },
    getPaPatient({ commit }, patientId) {
      APIservice.GET(`Patients/GetPatient/${patientId}`).then((res) =>
        commit('setMember', res.data)
      )
    },
    async cancelPaRequest({ dispatch }, params) {
      await APIservice.POST(`PaRequests/CancelPaRequest/${params.paRequestId}`).then((res) => {
        dispatch('getPaRequestByOliveTrackingId', params.oliveTrackingId)
      })
    },
    async copyPaRequestToDraft({}, paRequestId) {
      await APIservice.GET(`/PaRequests/CopyToDraft/${paRequestId}`).then((res) => {
        console.log(res)
        router.push({
          name: 'EditDraftPaRequest',
          params: { oliveTrackingId: res.data.oliveTrackingId },
        })
      })
    },
    async refreshPatientEligibility({ state, commit }, patientId) {
      await APIservice.GET(`Patients/RefreshPatientEligibility/${patientId}`).then(
        (res) => {
          console.log(res)
          let patient = Object.assign({}, state.paRequest, {
            patient: res.data,
          })
          commit('setPaRequestDetails', patient)
        }
      )
    },
  },
  mutations: {
    setPaRequestDetails(state, data) {
      state.paRequest = data
    },
    setMember(state, data) {
      state.member = data
    },
    setDifferences(state, data) {
      state.differences = data
    },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
  getters: {
    responsePaRequest(state) {
      if (!state.paRequest) {
        return false
      }

      let response = {...state.paRequest}
      if (!state.differences) {
        return response
      }

      const keys = Object.keys(state.differences)
      for (var i = 0; i < keys.length; i++) {
        let key = keys[i]
        if (!key || key.length < 2) {
          continue
        }

        let propName = utilities.convertPascalToCamelCase(key)
        if (response.hasOwnProperty(propName)) {
          response[propName] = state.differences[key]
        }
      }

      return response
    },
    responseDifferences(state) {
      if (!state.differences) {
        return []
      }

      let response = []

      Object.keys(includedDifferences).forEach(prop => {
        if (state.differences.hasOwnProperty(prop)) {
          response.push(prop)
        }
      })

      return response
    },
    displayedDifferences(state, getters) {
      return getters.responseDifferences.map(d => includedDifferences[d])
    },
  },
}
