import Vue from 'vue'
import moment from 'moment'

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('titleCase', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
})

Vue.filter('formatDateMMDDYYYY', function (value) {
  if (!value) return 'not set'
  return moment.utc(value).format('MM/DD/YYYY')
})

Vue.filter('formatDateLocalMMDDYYYY', function (value) {
  if (!value) return 'not set'
  return moment.utc(value).local().format('MM/DD/YYYY')
})

Vue.filter('formatTime', function (value) {
  if (!value) return 'not set'
  return moment.utc(value).local().format('h:mm a')
})

Vue.filter('formatDateTime', function (value) {
  if (!value) return 'not set'
  return moment.utc(value).local().format('MM/DD/YYYY h:mm a')
})

Vue.filter('formatDateTimeAgo', function (value) {
  if (!value) return 'not set'
  if (Math.abs(moment().diff(value)) < 60000) {
    return 'Just now'
  }
  return moment.utc(value).local().fromNow()
})

Vue.filter('formatYearsAgo', function (value) {
  if (!value) return 'not set'
  return moment.utc(value).local().fromNow(true)
})

Vue.filter('formatAge', function (value) {
  if (!value) return ''

  const dob = moment(value)
  const now = moment(new Date())
  const years = now.diff(dob, 'years')

  return `(${years} yrs)`
})

Vue.filter('formatPhoneNumber', function (value) {
  if (!value) return 'not set'

  let clean = ('' + value).replace(/\D/g, '')
  let split = clean.match(/^(\d{3})(\d{3})(\d{4})$/)

  // return raw value if we can't split on (3) 3-4 format
  if (!split || !split.length) return `${value}`

  let formatted = '(' + split[1] + ') ' + split[2] + '-' + split[3]
  return formatted
})

Vue.filter('formatCurrency', function (value) {
  const zero = '$0.00'
  if (!value) return zero

  let parsed = parseFloat(value)
  if (!parsed) return zero

  return parsed.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
})

Vue.filter('formatZipPlusFour', function (value) {
  if (!value) return ''

  if (value.length === 9) {
    return value.replace(/(\d{5})(\d{4})/, '$1-$2')
  }

  return value
})

Vue.filter('notSetIfEmpty', function (value) {
  if (!value) return 'not set'
  return value
})

Vue.filter('labelFromPascalCase', function (prop) {
  if (!prop) return ''
  return prop
    .split(/(?=[A-Z])/)
    .map((s) => s.toLowerCase().charAt(0).toUpperCase() + s.slice(1))
    .join(' ')
})

Vue.filter('daysFromToday', function (value) {
  if (!value) return null
  return moment.utc(value).diff(moment.utc().startOf(), 'days')
})

Vue.filter('absolute', function (value) {
  return Math.abs(value)
})

Vue.filter('durationFromNow', function (milliseconds) {
  if (!milliseconds || milliseconds < 0) return '0 seconds'
  return moment.duration(milliseconds).humanize({ s: 59, ss: 0 })
})

Vue.filter('formatLargeNum', function (quantity){
  var num = Number(quantity)
  const digits = '1'
  const lookup = [
    { value: 1e0, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  if(isNaN(num)) return quantity
  if(num.toFixed) {
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }
})

Vue.filter('formatDocumentName', function (documentName) {
  if (!documentName) {
    return 'not set'
  }
  /*
    Remove unique prefix added by backend
    2023-03-30-49-2231625_test.txt -> test.txt
  */
  return documentName.replace(/^\d{4}-\d{2}-\d{2}-\d{2}-\d{7}_/, '')
})