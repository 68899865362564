<template>
  <div id="rotateWarning">
    <div class="inner">
      <img src="/images/RotateDevice.svg" alt="portrait to landscape" aria-hidden="true"/>
      <h3>Rotate to continue.</h3>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RotateDevice',
    methods: {},
  }
</script>

<style lang="scss" scoped>
  #rotateWarning {
    position: fixed;
    z-index: 9999;
    background: rgba($color: var(--base-000), $alpha: 0.9);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
  }
  img {
    width: 25rem;
  }
  h3 {
    font-size: 2rem;
    font-weight: 300;
    color: var(--action-900);
    text-align: center;
    margin-top: $spacer-2x;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    #rotateWarning {
      display: flex;
    }
  }
</style>
