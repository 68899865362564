import APIservice from '@/api/APIservice'
import * as signalR from '@microsoft/signalr'
import router from '@/router'
import ENV from '@/config/environment'
import logger from '@/shared/logger'

function initialState() {
  return {
    connected: false,
    attempt: 0,
    closeConnection: false,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    connectToSocket: function ({ commit, dispatch, rootState }) {
      const shouldLog = ENV.NAME === 'Local' || ENV.NAME === 'Development'
      const socketRetryTimer = [0, 5, 10, 15, 30]
      let attempt = 0
      let token = () => sessionStorage.getItem('token')
      const options = {
        accessTokenFactory: token,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      }

      const connection = new signalR.HubConnectionBuilder()
        // .configureLogging(signalR.LogLevel.Trace)
        .withUrl(`${APIservice.URL}hubs/parequest`, options)
        .build()

      commit('setSocketClose', () => connection.stop())

      connection.on('PaRequestUpdated', (data) => {
        if (shouldLog) {
          console.log(data)
        }

        // If we are on a detail look if the updated requests is the current detail
        if (
          router.currentRoute.name === 'DetailPaRequest' &&
          router.currentRoute?.params?.oliveTrackingId ===
            data.model.oliveTrackingId
        ) {
          commit('detailPa/setPaRequestDetails', data.model, { root: true })
          commit('detailPa/setDifferences', data.differences, { root: true })
        }

        // if we are on the list update the single request in the list
        if (router.currentRoute.name === 'ListPaRequest') {
          let index = rootState.listPa.paRequestList.findIndex(
            (r) => r.paRequestId === data.model.paRequestId
          )
          if (index > -1) {
            let requests = [...rootState.listPa.paRequestList]
            requests.splice(index, 1, data.model)
            commit('listPa/setPaRequestList', requests, { root: true })
          }
        }
      })

      connection.on('NeedsActionUpdated', (data) => {
        if (shouldLog) {
          console.log(data)
        }
        if ( router.currentRoute.name === 'Dashboard' ) {
          dispatch('game/getNeedsActionRequests', null, { root: true })
        }
      })

      connection.on('AwardBadge', (data) => {
        if (shouldLog) {
          console.log(data)
        }
        commit('game/setBadgeAwarded', data.badges, { root: true })
      })

      connection.onclose(async (err) => {
        if (!sessionStorage.getItem('token')) {
          return
        }
        await start()
      })

      async function start() {
        if (attempt < socketRetryTimer.length) {
          const delayMs = socketRetryTimer[attempt] * 1000
          setTimeout(() => startSockectConnection(), delayMs)
          if (delayMs > 0) {
            logger.logSocketConnectRetry(delayMs)
          }
          attempt++
        } else {
          logger.logSocketConnectRetyExceeded()
        }
      }

      function startSockectConnection() {
        connection
          .start()
          .then(() => {
            attempt = 0
          })
          .catch(async (err) => {
            await start()
          })
      }

      start()
    },
  },
  mutations: {
    setSocketClose: (state, connection) => {
      state.closeConnection = connection
    },
    clearState(state) {
      if (state.closeConnection) {
        state.closeConnection()
      }
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
  getters: {},
}
