<template>
  <div
    v-if="isShown"
    class="newVersionMessage animate__animated animate__fadeInDown">
    <img src="/images/new-version.svg" alt="Gift Icon" aria-hidden="true" />
    <span>
      System updated, your page will automatically refresh
      <span v-if="!waitingForDocumentsToUpload">
        in
        <b>{{ refreshInMilliseconds | durationFromNow }}</b>
        .
      </span>
      <span v-if="waitingForDocumentsToUpload">when uploads complete.</span>
      <br />
      <span v-if="!uploadInProgress">
        Or get new features now:
        <a ref="refreshButton" href="#" @click.prevent="refresh">REFRESH</a>
      </span>
      <span v-if="uploadInProgress">&nbsp;</span>
    </span>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import APIservice from '@/api/APIservice'

  export default {
    name: 'NewVersionMessage',
    data() {
      return {
        intervalId: null,
        refreshInMilliseconds: 120000,
        countdownInterval: 1000,
        checkVersionInterval: 600000,
        version: null,
        isShown: false,
        waitingForDocumentsToUpload: false,
      }
    },
    computed: {
      ...mapGetters({
        uploadInProgress: 'document/uploadInProgress',
      })
    },
    mounted() {
      this.checkVersion()
      
      this.intervalId = setInterval(
        () => this.checkVersion(),
        this.checkVersionInterval)
    },
    destroyed() {
      clearInterval(this.intervalId)
    },
    watch: {
      uploadInProgress(value) {
        if (!value && this.waitingForDocumentsToUpload) {
          this.refresh()
        }
      },
    },
    methods: {
      refresh() {
        window.location.reload(true)
      },
      checkVersion() {
        APIservice.GET(`Overview/GetUIVersionInfo`)
          .then((res) => {
            if (!this.version) {
              this.version = res.data
              return
            }
            if (this.version !== res.data) {
              this.version = res.data
              this.display()
              this.$logger.logShowNewVersionMessage()
            }
          })
      },
      display() {
        this.isShown = true
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          () => {
            this.refreshInMilliseconds -= this.countdownInterval
            if (this.refreshInMilliseconds <= 0) {
              if (this.uploadInProgress) {
                this.waitingForDocumentsToUpload = true
                return
              }
              this.refresh()
            }
          },
          this.countdownInterval)
      },
    },
  }
</script>


<style scoped lang="scss">
  $message-width: 58rem;

  .newVersionMessage {
    background: var(--action-700);
    color: var(--base-050);
    position: absolute;
    left: calc(50% - $message-width / 2);
    bottom: $spacer-2x;
    width: $message-width;
    padding: $spacer-2x;
    border-radius: 3px;
    display: flex;
    font-size: 1.6rem;
    line-height: 2.4rem;;

    img {
      margin-right: $spacer-2x;
    }

    a {
      color: $color-accent-blue;
    }

    i {
      cursor: pointer;
    }
  }
</style>