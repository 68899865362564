import APIservice from '@/api/APIservice'
import router from '@/router'
import logger from '@/shared/logger'

function initialState() {
  return {
    memberSearchResult: false,
    responseMessage: '',
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async lookupMember({ state, commit, dispatch }, params) {
      commit('setResponseMessage', '')
      await APIservice.POST(`DraftPatients/SearchDraftPatient`, params, true)
        .then((res) => {
          commit('setMemberSearchResult', res.data)

          dispatch('auth/refreshAuthToken', null, { root: true })
          logger.logMemberLookup(res.status, '', params)
        })
        .catch((err) => {
          commit('setMemberSearchResult', null)
          let message = {
            displayName: 'No Member Found',
            displayMessage: 'An error occurred, please try again.',
          }
          
          if (err.response.data) {
            message = err.response.data
          }

          commit('setResponseMessage', message)
          
          logger.logMemberLookup(
            err.response.status,
            state.responseMessage.displayName,
            params
          )
          // if FL Blue is down show error screen
          if (err.response.status === 400) {
            router.push({ name: 'ErrorSplash' })
          }
        })
    },
  },
  mutations: {
    setMemberSearchResult(state, data) {
      state.memberSearchResult = data
    },
    setResponseMessage(state, data) {
      state.responseMessage = data
    },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
}
