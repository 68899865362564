export default {
  convertPascalToCamelCase(pascal) {
    return pascal.charAt(0).toLowerCase() + pascal.slice(1)
  },
  poll: async function (fn, fnCondition, ms) {
    let result = await fn();
    while (fnCondition(result)) {
      await this.wait(ms);
      result = await fn();
    }
    return result;
  },  
  wait(ms = 5000) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  },
}