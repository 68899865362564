import APIservice from '@/api/APIservice'

function initialState() {
  return {
    message: '',
    error: '',
    isLoading: false,
    isVerifyingEmail: false,
    isSettingUpSms2fa: false,
    isVerifyingSms: false,
    isSettingUpAuthApp2fa: false,
    isVerifyingAuthApp: false,
    authApp: {
      sharedKey: '',
      authenticatorUri: '',
    },
    setupMethods: [],
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async setupEmail2fa({ commit }) {
      commit('setLoading')

      await APIservice.GET(`Auth/SetupTwoFactorEmail`)
        .then(async () => {
          commit('verifyEmail')
        })
        .catch(() => {
          commit('setError', 'There was an error setting up email codes.')
        })

      commit('resetLoading')
    },
    async verifyEmail2fa({ commit, dispatch }, params) {
      commit('setLoading')

      await APIservice.POST(`Auth/VerifyTwoFactorEmail`, {
        verificationCode: params.code,
      })
        .then(async (res) => {
          commit('setMessage', 'Email codes successfully setup.')
          commit('completeVerifyEmail')
          dispatch('auth/setUserSession', res.data, { root: true })
        })
        .catch(() => {
          commit('setError', 'There was an error verifying code.')
        })

      commit('resetLoading')
    },
    async setupSms2fa({ commit }, params) {
      commit('setLoading')

      let updated = await APIservice.POST(`Auth/UpdateCellPhoneNumber`, {
        cellPhoneNumber: params.phone,
      })
        .then(() => {
          return true
        })
        .catch(() => {
          return false
        })

      if (!updated) {
        commit(
          'setError',
          "There was an error updating your account's phone number"
        )
        commit('resetLoading')
        return
      }

      await APIservice.POST(`Auth/SetupTwoFactorSms`, {
        password: params.password,
      })
        .then(() => {
          commit('verifySms')
        })
        .catch(() => {
          commit('setError', 'There was an error setting up text codes.')
        })

      commit('resetLoading')
    },
    async verifySms2fa({ commit, dispatch }, params) {
      commit('setLoading')

      await APIservice.POST(`Auth/VerifyTwoFactorSms`, {
        verificationCode: params.code,
      })
        .then(async (res) => {
          commit('setMessage', 'Text codes successfully setup.')
          commit('completeVerifySms')
          dispatch('auth/setUserSession', res.data, { root: true })
        })
        .catch(() => {
          commit('setError', 'There was an error verifying code.')
        })

      commit('resetLoading')
    },
    async setupAuthApp2fa({ commit }, params) {
      commit('setLoading')

      await APIservice.POST(`Auth/SetupTwoFactorAuthenticationApp`, {
        password: params.password,
      })
        .then((res) => {
          commit('setAuthApp', res.data)
          commit('verifyAuthApp')
        })
        .catch(() => {
          commit('setError', 'There was an error setting up text codes.')
        })

      commit('resetLoading')
    },
    async verifyAuthApp2fa({ commit, dispatch }, params) {
      commit('setLoading')

      await APIservice.POST(`Auth/VerifyAuthenticatorApp`, {
        verificationCode: params.code,
      })
        .then(async (res) => {
          commit('setMessage', 'Authentication app successfully setup.')
          commit('completeVerifyAuthApp')
          dispatch('auth/setUserSession', res.data, { root: true })
        })
        .catch(() => {
          commit('setError', 'There was an error verifying code.')
        })

      commit('resetLoading')
    },
    async reset2fa({ commit, dispatch }) {
      commit('setLoading')

      await APIservice.POST(`Auth/Clear2FAForUser`)
        .then(async () => {
          await dispatch('RESET_GLOBAL_STATE', true, { root: true })
        })
        .catch(() => {
          commit(
            'setError',
            'There was an error resetting your two factor authentication.'
          )
        })

      commit('resetLoading')
    },
    async loadSetupMethods({ commit }) {
      commit('setLoading')

      await APIservice.GET('Auth/GetPossibleTwoFactorMethods')
        .then(async (res) => {
          commit('setSetupMethods', res.data)
        })
        .catch(() => {
          commit('setError', 'There was an error retrieving possible two factor methods.')
        })

      commit('resetLoading')
    },
  },
  mutations: {
    setMessage(state, data) {
      state.error = ''
      state.message = data
    },
    setError(state, data) {
      state.message = ''
      state.error = data
    },
    setLoading(state) {
      state.isLoading = true
    },
    resetLoading(state) {
      state.isLoading = false
    },
    verifyEmail(state) {
      state.message = ''
      state.error = ''
      state.isVerifyingEmail = true
    },
    completeVerifyEmail(state) {
      state.isVerifyingEmail = false
    },
    setupSms2fa(state) {
      state.message = ''
      state.error = ''
      state.isVerifyingSms = false
      state.isSettingUpSms2fa = true
    },
    verifySms(state) {
      state.message = ''
      state.error = ''
      state.isVerifyingSms = true
      state.isSettingUpSms2fa = false
    },
    completeVerifySms(state) {
      state.isVerifyingSms = false
    },
    setupAuthApp2fa(state) {
      state.message = ''
      state.error = ''
      state.isSettingUpAuthApp2fa = true
    },
    verifyAuthApp(state) {
      state.message = ''
      state.error = ''
      state.isVerifyingAuthApp = true
      state.isSettingUpAuthApp2fa = false
    },
    completeVerifyAuthApp(state) {
      state.isVerifyingAuthApp = false
    },
    setAuthApp(state, data) {
      state.authApp = data
    },
    setSetupMethods(state, data) {
      state.setupMethods = data
    },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
}
