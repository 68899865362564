import APIservice from '@/api/APIservice'
import logger from '@/shared/logger'
import uploadQueue from '@/shared/uploadQueue'

function initialState() {
  return {
    paRequestDocuments: false,
    paRequestDocumentIds: [],
    draftDocuments: false, // still need to be sent to the payer
    uploading: [],
    nextUploadId: 0,
    hasUploadRecentlyCompleted: false,
    hasRecentUploadError: false,
    uploadCompleteTimeoutId: null,
    error: '',
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async getDocumentsForPaRequest({ commit }, params) {
      commit('clearError')
      await APIservice.GET(
        `/BlobDocuments/GetBlobDocumentsForPaRequest/${params.paRequestId}`
      )
        .then((res) => {
          if (params.isDraft) {
            commit('setDraftDocuments', res.data)
          } else {
            commit('setPaRequestDocuments', res.data)
          }
        })
        .catch((err) => {
          console.log(err)
          commit('setError', 'Error getting documents. Please try again later.')
        })
    },
    removeDocumentFromPaRequest({ state, dispatch }, data) {
      APIservice.POST(
        `/BlobDocuments/RemoveBlobDocument/${data.paId}/${data.documentId}`,
        {}
      ).then(() => {
        dispatch('getDocumentsForPaRequest', {
          paRequestId: data.paId,
          isDraft: true,
        })
      })
    },
    async setDocumentClass({ commit }, params) {
      await APIservice.PUT(
        `BlobDocuments/PutBlobDocument/${params.blobDocumentId}`,
        params
      ).then(() => {
        commit('setDocumentClass', params)
      })
    },
    handleFileDropEvent({ dispatch }, params) {
      const event = params.event
      const files = []

      if (event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (event.dataTransfer.items[i].kind === 'file') {
            const file = event.dataTransfer.items[i].getAsFile()
            files.push(file)
          }
        }
      } else {
        // For IE, use DataTransfer interface to access the file(s)
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          const file = event.dataTransfer.files[i]
          files.push(file)
        }
      }

      logger.logUploadEvent('files-dropped', files)

      dispatch('uploadDocumentsToPaRequest', {
        paRequestId: params.paRequestId,
        files: files,
      })
    },
    uploadDocumentsToPaRequest({ state, commit, dispatch }, params) {
      if (state.hasUploadRecentlyCompleted) {
        commit('setHasRecentUploadError', false)
        commit('setHasUploadRecentlyCompleted', false)
      }
      clearTimeout(state.uploadCompleteTimeoutId)

      for (var i = 0; i < params.files.length; i++) {
        commit('incrementUploadId')

        const file = params.files[i]
        const data = new FormData()
        data.append('file', file)
        data.append('paRequestId', params.paRequestId)
        data.append('description', file.name)

        const queueEntry = {
          paRequestId: params.paRequestId,
          uploadId: state.nextUploadId,
          data: data,
          name: file.name,
          fileType: file.type,
          totalBytes: file.size,
          uploadedBytes: 0,
          percentUploaded: 0,
        }
        commit('pushToUploading', queueEntry)

        if (file.size > 20971520) {
          commit('addErrorToQueueEntry', {
            ...queueEntry,
            error: 'Larger than 20MB',
          })
          dispatch('updateRecentUploadState', {
            isError: true,
            paRequestId: queueEntry.paRequestId,
          })
          continue
        }

        if (file.size < 1) {
          commit('addErrorToQueueEntry', {
            ...queueEntry,
            error: 'File is empty',
          })
          dispatch('updateRecentUploadState', {
            isError: true,
            paRequestId: queueEntry.paRequestId,
          })
          continue
        }

        dispatch('uploadDocumentToPaRequest', queueEntry)
      }
    },
    uploadDocumentToPaRequest({ commit, dispatch, getters }, queueEntry) {
      queueEntry.onProgressUpdate = (progressEvent) => {
        dispatch('updateQueueEntryProgress', {
          uploadId: queueEntry.uploadId,
          uploadedBytes: progressEvent.loaded,
          totalBytes: progressEvent.total,
        })
      }

      // If we have the pa detail and it has a reference number we need to
      // send the document to be immediately sent to payer
      queueEntry.sendToPayer = getters.hasReferenceNumber
      
      // Successful
      queueEntry.success = (res) => {
        commit('removeFromUploading', queueEntry.uploadId)
        commit('appendDraftDocuments', res.data)
        dispatch('auth/refreshAuthToken', null, { root: true })
        dispatch('updateRecentUploadState', {
          isError: false,
          paRequestId: queueEntry.paRequestId,
        })
      }

      // Failed
      queueEntry.fail = (err) => {
        commit('addErrorToQueueEntry', {
          ...queueEntry,
          error: err.response?.data || 'Unknown Error',
        })
        dispatch('updateRecentUploadState', {
          isError: true,
          paRequestId: queueEntry.paRequestId,
        })
      }

      uploadQueue.enqueue(queueEntry)
    },
    updateRecentUploadState({ commit, dispatch, getters, rootState }, params) {
      if (params.isError) {
        commit('setHasRecentUploadError', true)
      }

      if (!getters.uploadInProgress) {
        commit('setHasUploadRecentlyCompleted', true)
        commit(
          'setUploadCompleteTimeoutId',
          setTimeout(() => {
            commit('setHasRecentUploadError', false)
            commit('setHasUploadRecentlyCompleted', false)
          }, 10000)
        )
      }
    },
    updateQueueEntryProgress({ state, commit }, params) {
      const index = state.uploading.findIndex(
        (e) => e.uploadId === params.uploadId
      )
      if (index >= 0) {
        commit('updateQueueEntryProgress', {
          index,
          uploadedBytes: params.uploadedBytes,
          totalBytes: params.totalBytes,
          percentUploaded: (params.uploadedBytes / params.totalBytes) * 100,
        })
      }
    },
    removeFailedQueueEntries({ state, commit }) {
      for (var i = state.uploading.length - 1; i >= 0; i--) {
        var entry = state.uploading[i]
        if (entry.error) {
          commit('removeFromUploading', entry.uploadId)
        }
      }
    },
  },
  mutations: {
    setPaRequestDocuments(state, data) {
      state.paRequestDocuments = data
      state.paRequestDocumentIds = state.paRequestDocuments.map(
        (document) => document.blobDocumentId
      )
    },
    setDraftDocuments(state, data) {
      const documents = data.filter(
        (document) =>
          !state.paRequestDocumentIds.includes(document.blobDocumentId)
      )
      state.draftDocuments = documents
    },    
    appendDraftDocuments(state, data) {
      const documents = data.filter(
        (document) =>
          !state.paRequestDocumentIds.includes(document.blobDocumentId)
      )
      state.draftDocuments = [...state.draftDocuments, ...documents]
    },
    clearDraftDocuments(state) {
      state.draftDocuments = []
    },
    setDocumentClass(state, params) {
      const document = state.draftDocuments.find(
        (d) => d.blobDocumentId === params.blobDocumentId
      )
      document.docTypeId = params.docTypeId
    },
    setError(state, error) {
      state.error = error
    },
    clearError(state) {
      state.error = ''
    },
    pushToUploading(state, data) {
      state.uploading.push(data)
    },
    clearUploading(state) {
      state.uploading = []
    },
    removeFromUploading(state, uploadId) {
      const index = state.uploading.findIndex((f) => f.uploadId === uploadId)
      if (index || index === 0) {
        state.uploading.splice(index, 1)
      }
    },
    addErrorToQueueEntry(state, queueEntry) {
      state.uploading = state.uploading.map((entry) => {
        if (entry.uploadId === queueEntry.uploadId) {
          return { ...entry, ...queueEntry }
        }
        return entry
      })
      logger.logUploadError(queueEntry)
    },
    updateQueueEntryProgress(state, params) {
      const queueEntry = state.uploading[params.index]
      if (queueEntry) {
        queueEntry.uploadedBytes = params.uploadedBytes
        queueEntry.totalBytes = params.totalBytes
        queueEntry.percentUploaded = params.percentUploaded
      }
    },
    incrementUploadId(state) {
      state.nextUploadId++
    },
    setHasUploadRecentlyCompleted(state, data) {
      state.hasUploadRecentlyCompleted = data
    },
    setHasRecentUploadError(state, data) {
      state.hasRecentUploadError = data
    },
    setUploadCompleteTimeoutId(state, timeoutId) {
      state.uploadCompleteTimeoutId = timeoutId
    },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
  getters: {
    uploadInProgress: (state) => {
      if (!state.uploading.length) {
        return false
      }

      for (var i = 0; i < state.uploading.length; i++) {
        if (!state.uploading[i].error) {
          return true
        }
      }

      return false
    },
    totalUploadProgressPercent: (state) => {
      let total = 0
      let uploaded = 0

      for (var i = 0; i < state.uploading.length; i++) {
        const entry = state.uploading[i]
        if (!entry.error) {
          total += entry.totalBytes
          uploaded += entry.uploadedBytes
        }
      }

      return (uploaded / total) * 100
    },
    hasReferenceNumber: (state, getters, rootState) => {
      return !!rootState.detailPa.paRequest?.referenceNumber
    },
  },
}
