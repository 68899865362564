import axios from 'axios'

export default {
  install: (Vue) => {
    Vue.prototype.$OliveMFE = Vue.prototype.$OliveMFE || {}
    Vue.prototype.$MFEloader = async function (MFEcomponent, host) {
      // constants
      const scriptID = `${MFEcomponent}-scripts`
      const styleId = `${MFEcomponent}-styles`
      let jsPackageName = 'app.js'
      let cssPackageName = 'app.css'
      const version = 'version'

      if (document.getElementById(scriptID)) {
        const script = document.getElementById(scriptID)
        script.parentNode.removeChild(script)

        const link = document.getElementById(styleId)
        link.parentNode.removeChild(link)

        window.OliveMFE[MFEcomponent] = null
      }

      let res = {}
      const now = Date.now()
      try {
        res = await axios.get(`${host}/asset-manifest.json?time=${now}`)
      } catch(err) {
        console.log(err)
        Vue.prototype.$logger.logMFEFailedToLoad(MFEcomponent, host)
        Vue.prototype.$OliveMFE[MFEcomponent] = {error: `Unable to load asset-manifest from ${host}`}
        return 
      }
     
      const manifest = res.data
      const script = document.createElement('script')
      const link = document.createElement('link')

      if(!manifest[jsPackageName]) { // Check if the manifest is produced by the legacy webpack version.
        jsPackageName = 'src/main.js'
        cssPackageName = 'style.css'
        
        script.setAttribute('type', 'module')
        script.setAttribute('src', `${host}/${manifest[jsPackageName].file}`)
        link.setAttribute('href', `${host}/${manifest[cssPackageName].file}`)
      } else {
        script.setAttribute('type', 'text/javascript')
        script.setAttribute('src', `${host}/${manifest[jsPackageName]}`)
        link.setAttribute('href', `${host}/${manifest[cssPackageName]}`)
      }

      
      script.setAttribute('crossOrigin', true)
      script.setAttribute('id', scriptID)

      link.setAttribute('rel', 'stylesheet')
      link.setAttribute('crossOrigin', true)
      link.setAttribute('id', styleId)

      return new Promise((resolve, reject) => {
        script.addEventListener('load', () => {
          if (!window.OliveMFE || !window.OliveMFE[MFEcomponent]) {
            Vue.prototype.$logger.logMFEFailedToAttach(MFEcomponent)
            return reject(new Error(`The [${MFEcomponent}] MFE component is not attached to the window.`))
          }
          Vue.prototype.$OliveMFE[MFEcomponent] = { ...window.OliveMFE[MFEcomponent], version: manifest[version] }
          return resolve(window.OliveMFE[MFEcomponent])
        })
        script.addEventListener('error', (e) => {
          reject(e.error)
        })
        document.head.appendChild(script)
        document.head.appendChild(link)
      })
    }
  },
}