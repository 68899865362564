import APIservice from '@/api/APIservice'
import { provideIntlService } from '@progress/kendo-vue-intl'

function initialState() {
  return {
    needsAction: false,
    badgeAwarded: false,
    userEarnedBadges: false,
    stats: false,
    gameStats: false,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    getNeedsActionRequests({ commit }) {
      const params = {
        "take": 100,
        "skip": 0,
        "sort": [{ "field": "statusLastUpdated", "dir": "desc" }]  
      }
      APIservice.POST(`PaRequests/NeedsActionPaginated`, params).then((res) =>
        commit('setNeedsAction', res.data)
      )
    },
    getGameificationOverview({commit}) {
      APIservice.GET(`Gamification/GetGameificationOverview`).then((res) =>
        commit('setGameStats', res.data)
      )
    },
    getBadgesForUser({commit}) {
      APIservice.GET(`Gamification/GetBadgesForUser`).then((res) =>
        commit('setUserEarnedBadges', res.data)
      )
    },
  },
  mutations: {
    setNeedsAction(state, requests) {
      state.needsAction = requests
    },
    setBadgeAwarded(state, badge) {
      state.badgeAwarded = badge
    },
    setUserEarnedBadges(state, badges) {
      state.userEarnedBadges = badges
    },
    setBadges(state, badges) {
      state.badges = badges
    },
    setStats(state, stats) {
      state.stats = stats
    },
    setGameStats(state, stats) {
      state.gameStats = stats
    },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
  getters: {
    allBadges: (state, getters, rootState) => {
      return rootState.staticData.overview?.badges
    },
    earnedBadges: (state, getters) => {
      if (!getters.allBadges?.length || !state.badgeAwarded?.length) {
        return false
      }

      return state.badgeAwarded.map((badge) => {
        let badgeFromAllBadges = getters.allBadges.find(b => {
          return b.badgeId === badge.badgeId
        })

        let badgeAwardNumber = badgeFromAllBadges.levels.find( bn =>{
          return bn.level === badge.level
        })

        return {
          id: badge.badgeId,
          title: badgeAwardNumber?.name,
          label: badgeAwardNumber?.requirements[0].countToAward,
          description: badgeAwardNumber?.description,
          level: badge.level,
          earned: Date.now(),
        }
      })
    },
    recentBadgesEarned: (state, getters) => {
      if( !getters.allBadges?.length || !state.userEarnedBadges?.length ){
        return false
      }

      return state.userEarnedBadges.map((badge) => {
        let badgeFromAllBadges = getters.allBadges.find( b => {
          return b.badgeId === badge.level.badgeId
        })

        let badgeAwardNumber = badgeFromAllBadges.levels.find( bn =>{
          return bn.level === badge.level.level
        })

        return {
          id: badge.level.badgeId,
          title: badgeAwardNumber?.name,
          label: badgeAwardNumber?.requirements[0].countToAward,
          description: badgeAwardNumber?.description,
          level: badge.level.level, 
          earned: badge.createdOn,
        }
      })
    }
  },
}
