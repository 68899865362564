import APIservice from '@/api/APIservice'

function initialState() {
  return {
    profile: null,
    supportOrganizationId: sessionStorage.getItem('supportOrganizationId'),
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadProfile({ state, dispatch }) {
      if (state.profile) {
        return
      }

      dispatch('profileRefresh')
    },
    async profileRefresh({ commit }) {
      await APIservice.GET(`Profile/GetProfileInfo`).then((res) =>
        commit('setProfile', res.data)
      )
    },
  },
  mutations: {
    setProfile(state, profile) {
      state.profile = profile
    },
    setSupportOrganizationId(state, organizationId) {
      state.supportOrganizationId = organizationId
      sessionStorage.setItem('supportOrganizationId', organizationId)
    },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
}
