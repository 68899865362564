// import paRequests from '@/api/paRequests'
import APIservice from '@/api/APIservice'

function initialState() {
  return {
    paRequestList: false,
    draftPaRequestList: false,
    userArchivedPaRequestList: false,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    getPaRequests({ commit }) {
      APIservice.GET(`PaRequests/GetPaRequestsForUser`).then((res) =>
        commit('setPaRequestList', res.data)
      )
    },
    getDraftPaRequests({ commit }) {
      APIservice.GET(`DraftPaRequests/GetDraftPaRequestsForUser`).then((res) =>
        commit('setDraftPaRequestList', res.data)
      )
    },
    async deleteDraftPaRequests({ dispatch }, draftPaRequestId) {
      await APIservice.DELETE(
        `DraftPaRequests/DeleteDraftPaRequest/${draftPaRequestId}`
      ).then(() => dispatch('getDraftPaRequests'))
    },
    async archivePaRequest({ state, commit }, paRequestId) {
      let data = {
        paRequestId: paRequestId,
        archive: true,
      }
      await APIservice.POST(`PaRequests/ArchivePaRequest`, data).then((res) => {
        if (state.paRequestList) {
          let requestList = state.paRequestList.filter((r) => {
            return r.paRequestId !== paRequestId
          })
          commit('setPaRequestList', requestList)
        }
      })
    },
    async restorePaRequest({ state, commit }, paRequestId) {
      let data = {
        paRequestId: paRequestId,
        archive: false,
      }
      await APIservice.POST(`PaRequests/ArchivePaRequest`, data).then((res) => {
        if (state.userArchivedPaRequestList) {
          let archiveList = state.userArchivedPaRequestList.filter((r) => {
            return r.paRequestId !== paRequestId
          })
          commit('setPaRequestList', archiveList)
        }
      })
    },
    // async getUserArchivedPaRequests({ commit }) {
    //   APIservice.GET(`PaRequests/GetArchivedPaRequestsForUser`).then((res) =>
    //     commit('setUserArchivedPaRequestList', res.data)
    //   )
    // },
    // async lookupArchivedRequests({ commit }, params) {
    //   await APIservice.POST(
    //     `PaRequests/SearchArchivedPaRequestsWithPatientInfo`,
    //     params
    //   )
    //     .then((res) => {
    //       commit('setUserArchivedPaRequestList', res.data)
    //     })
    //     .catch(() => {
    //       commit('setUserArchivedPaRequestList', null)
    //     })
    // },
  },
  mutations: {
    setPaRequestList(state, data) {
      state.paRequestList = data
    },
    setDraftPaRequestList(state, data) {
      state.draftPaRequestList = data
    },
    // setUserArchivedPaRequestList(state, data) {
    //   state.userArchivedPaRequestList = data
    // },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
}
