import Vue from 'vue'
import ENV from '@/config/environment'
import store from '@/store'

export default {
  telemetryInitializer(envelope) {
    envelope.data.environment = ENV.NAME
    envelope.data.build = BUILDNUMBER || 'Local'
    envelope.data.commit = COMMITHASH
    envelope.data.windowHeight = window?.innerHeight || 'unknown'
    envelope.data.windowWidth = window?.innerWidth || 'unknown'
    envelope.data.screenHeight = window?.screen?.availHeight || 'unknown'
    envelope.data.screenWidth = window?.screen?.availWidth || 'unknown'
    envelope.data.userGuid = store.state.auth.userGuid || 'unknown'
  },
  logGorillaEvent(eventName, data, oliveTrackingId) {
    if (!Vue.appInsights) {
      return
    }

    try {
      let createPa = false
      let detailPa = false
      if (store.state.createPa.draftPaState) {
        oliveTrackingId = oliveTrackingId || store.state.createPa.draftPaState.oliveTrackingId
        createPa = true
      } else if (store.state.detailPa.paRequest) {      
        oliveTrackingId = oliveTrackingId || store.state.detailPa.paRequest.oliveTrackingId
        detailPa = true
      }
      
      Vue.appInsights.trackEvent({
        name: eventName,
        properties: {
          oliveTrackingId: oliveTrackingId || 'unknown',
          createPa,
          detailPa,
          ...data,
        }
      })
    } catch(err) {
      console.warn(err)
    }
  },
  logSocketConnectRetry(delayMs) {
    try {
      this.logGorillaEvent('socket-connection-failed-retrying', { delayMs })
    } catch(err) {
      console.warn(err)
    }
  },
  logSocketConnectRetyExceeded() {
    try {
      this.logGorillaEvent('socket-connection-failed-retry-exceeeded', {})
    } catch(err) {
      console.warn(err)
    }
  },
  logUploadEvent(eventName, files) {
    try {
      // files may be an array or FileList so we must use an explicit for loop
      const fileSummaries = []
      for (var i = 0; i < files.length; i++) {
        var file = files[i]
        fileSummaries.push({
          size: file.size,
          type: file.type,
        })
      }

      this.logGorillaEvent(eventName, {
        files: fileSummaries,
        numberFiles: fileSummaries.length,
      })
    } catch(err) {
      console.warn(err)
    } 
  },
  logUploadError(uploadQueueEntry) {
    try {
      this.logGorillaEvent('upload-error', {
        error: uploadQueueEntry.error,
        size: uploadQueueEntry.totalBytes,
        type: uploadQueueEntry.fileType,
      })
    } catch(err) {
      console.warn(err)
    }
  },  
  logMissingSupportedProcedureWarning() {
    try {
      const stemps = store.state.createPa.draftPaState?.requestedStemp?.map(
        (r) => r.stempCode.code
      )
      this.logGorillaEvent('missing-supported-procedure', { stemps })
    } catch(err) {
      console.warn(err)
    }
  },
  logMissingRequirementsWarning() {
    try {
      this.logGorillaEvent('missing-required-props', {
        missingRequiredProps: store.getters['createPa/missingRequiredProps'],
        missingProcedureProps: store.getters['createPa/missingProcedureProps'],
      })
    } catch(err) {
      console.warn(err)
    }
  },
  logAttestationSkipped(stemp) {
    try {
      this.logGorillaEvent('attestation-skipped', { stemp })
    } catch(err) {
      console.warn(err)
    }
  },
  logMFEFailedToLoad(component, host) {
    try {
      this.logGorillaEvent('MFE-failed-to-load', { component: component, host: host })
    } catch (err) {
      console.warn(err)
    }
  },
  logMFEFailedToAttach(component) {
    try {
      this.logGorillaEvent('MFE-failed-to-attach', { component: component })
    } catch (err) {
      console.warn(err)
    }
  },
  logMemberLookup(statusCode, responseMessage, params) {
    try {
      this.logGorillaEvent('member-lookup', {
        statusCode,
        responseMessage,
        includesMemberId: !!params.memberId,
        includesDateOfBirth: !!params.dateOfBirth,
        includesLastName: !!params.lastName,
        includesFirstName: !!params.firstName,
      })
    } catch(err) {
      console.warn(err)
    }
  },
  logMySubmissionsTabChanged(tabName) {
    try {
      this.logGorillaEvent('my-submissions-tab-changed', { tabName })
    } catch(err) {
      console.warn(err)
    }
  },
  logCoverageDetailsExpanded() {
    try {
      this.logGorillaEvent('coverage-details-expanded', {})
    } catch(err) {
      console.warn(err)
    }
  },
  logContinueDraft(from, oliveTrackingId) {
    try {
      this.logGorillaEvent('draft-continued', { from }, oliveTrackingId)
    } catch(err) {
      console.warn(err)
    }
  },
  logAbandonedSupportForm(summary, oliveTrackingId) {
    try {
      this.logGorillaEvent(
        'support-form-abandoned',
        { summary },
        oliveTrackingId)
    } catch(err) {
      console.warn(err)
    }
  },
  logAiAnswerChanged(questionId, stempCode) {
    try {
      this.logGorillaEvent(
        'ai-attestation-answer-changed', { 
          stemp: stempCode,
          questionId,
        })
    } catch(err) {
      console.warn(err)
    }
  },
  logTimeWaitingForDocumentsAnalyzed(timespanMilliseconds) {
    try {
      this.logGorillaEvent('time-waiting-for-documents-analyzed', { timespanMilliseconds })
    } catch(err) {
      console.warn(err)
    }
  },
  logShowNewVersionMessage() {
    try {
      this.logGorillaEvent('new-version-message-displayed', {})
    } catch (err) {
      console.warn(err)
    }
  },
  logDateEntered(field, onPaste, isValid) {
    try {
      this.logGorillaEvent('date-entered', {
        field: field || 'unknown',
        onPaste,
        onBlur: !onPaste,
        isValid,
      })
    } catch(err) {
      console.warn(err)
    }
  },
  logCreationStepUpdated(creationStep) {
    try {
      this.logGorillaEvent('creation-step-updated', { creationStep })
    } catch(err) {
      console.warn(err)
    }
  },
  logDraftUpdated(action, params) {
    try {
      this.logGorillaEvent('draft-updated', { action, ...params })
    } catch(err) {
      console.warn(err)
    }
  },
  logValuePasted(action, params) {
    try {
      this.logGorillaEvent('value-pasted', { action, ...params })
    } catch(err) {
      console.warn(err)
    }
  },
  logDetailTabChanged(tabName) {
    try {
      this.logGorillaEvent('detail-tab-changed', { tabName })
    } catch(err) {
      console.warn(err)
    }
  },
  logWaitForPayerResponseClose(event) {
    try {
      this.logGorillaEvent('detail-wait-for-payer-close', { type: event })
    } catch (err) {
      console.warn(err)
    }
  },
  logShowAllResults(field) {
    try {
      this.logGorillaEvent('show-all-results-clicked', { field })
    } catch(err) {
      console.warn(err)
    }
  },
  logShowSessionExpiringModal() {
    try {
      this.logGorillaEvent('session-expiring-modal-displayed', {})
    } catch (err) {
      console.warn(err)
    }
  },
  logSignOut(userTriggered) {
    try {
      this.logGorillaEvent('sign-out', { userTriggered: !!userTriggered })
    } catch (err) {
      console.warn(err)
    }
  },
  logContinueSession() {
    try {
      this.logGorillaEvent('continue-session-from-modal', {})
    } catch (err) {
      console.warn(err)
    }
  },
  logNavigation(from, to, timeOnPreviousView) {
    try {
      this.logGorillaEvent('navigation-occurred', { from, to, timeOnPreviousView })
    } catch (err) {
      console.warn(err)
    }
  },
  logRequestingProviderSelected() {
    try {
      this.logGorillaEvent('requesting-provider-selected', {})
    } catch (err) {
      console.warn(err)
    }
  },
  logRequestingProviderRemoved() {
    try {
      this.logGorillaEvent('requesting-provider-removed', {})
    } catch (err) {
      console.warn(err)
    }
  },
}
