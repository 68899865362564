import Vue from 'vue'
import Vuex from 'vuex'
import staticData from './modules/staticData'
import auth from './modules/auth'
import detailPa from './modules/detailPa'
import listPa from './modules/listPa'
import createPa from './modules/createPa'
import searchPa from './modules/searchPa'
import navigation from './modules/navigation'
import account from './modules/account'
import memberLookup from './modules/memberLookup'
import attestation from './modules/attestation'
import accountSecurity from './modules/accountSecurity'
import socketConnection from './modules/socketConnection'
import document from './modules/document'
import reports from './modules/reports'
import game from './modules/game'
import router from '@/router'
import APIservice from '@/api/APIservice'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    staticData,
    detailPa,
    listPa,
    createPa,
    searchPa,
    navigation,
    account,
    memberLookup,
    attestation,
    accountSecurity,
    socketConnection,
    document,
    reports,
    game,
  },
  actions: {
    RESET_GLOBAL_STATE({ commit, state }, signin) {
      router.push({ name: 'SignOut' })
      // clear session storage before resetting state to ensure any state that
      // initializes from session storage does not pikc up the existing value
      APIservice.ABORT()
      sessionStorage.clear()
      Object.keys(state).forEach((key) => {
        commit(`${key}/clearState`)
      })
      if(!signin) {
        window.location.replace('https://apps.availity.com/availity/web/public.elegant.login')
        return
      }
      router.push({ name: 'SignIn' })
    },

  },
  getters: {
    requiresOrganizationSelection: (state, getters) => {
      return (
        getters['auth/isOliveSupport'] && !state.account.supportOrganizationId
      )
    },
  },
  strict: process.env.NODE_ENV !== 'production',
})
