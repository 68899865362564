import axios from 'axios'
import ENV from '../config/environment'
import store from '@/store'
import 'promise-polyfill/src/polyfill'
import 'unfetch/polyfill'
import 'abortcontroller-polyfill'

axios.defaults.baseURL = ENV.API

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    console.log(error)
    if (error.response?.status === 401 && error.response?.config.url !== 'Auth/CreateToken') {
      store.dispatch('RESET_GLOBAL_STATE')
    }
    return Promise.reject(error)
  }
)

let controller = new AbortController()
let uploadController = new AbortController()
const headers = () => {
  return {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    Pragma: 'no-cache',
  }
}

export default {
  URL: ENV.API,
  ABORT: () => {
    controller.abort()
    controller = new AbortController()
  },
  ABORT_UPLOAD: () => {
    uploadController.abort()
    uploadController = new AbortController()
  },
  DELETE: (url) => {
    const baseHeaders = headers()
    return axios.delete(url, {
      headers: baseHeaders,
    })
  },
  GET: (url) => {
    const baseHeaders = headers()
    return axios.get(url, {
      signal: controller.signal,
      headers: baseHeaders,
    })
  },
  POST: (url, params, abort = false) => {
    const baseHeaders = headers()
    return axios.post(url, params, {
      signal: abort ? controller.signal : false,
      headers: baseHeaders,
    })
  },
  PUT: (url, params) => {
    const baseHeaders = headers()
    return axios.put(url, params, {
      headers: baseHeaders,
    })
  },
  UPLOAD: (url, file, onUploadProgress) => {
    const baseHeaders = headers()
    let uploadHeaders = Object.assign({}, baseHeaders, {
      'Conent-Type': 'multipart/form-data',
    })
    return axios.post(url, file, {
      headers: uploadHeaders,
      onUploadProgress: onUploadProgress,
      signal: uploadController.signal,
    })
  },
}
