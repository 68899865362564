import APIservice from '@/api/APIservice'

function initialState() {
  return {
    reports: [],
    groups: [],
    dateRange: null,
    reportData: null,
    isRunningReport: false,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async getReports({ commit }) {
      await APIservice.GET(`Report/Index`).then((res) =>
        commit('setReports', res.data)
      )
    },
    async getReportGroups({ commit }) {
      await APIservice.GET(`Report/GetReportsByGroup`).then((res) =>
        commit('setGroups', res.data)
      )
    },
    async runReport({ commit, dispatch }, params) {
      commit('setIsRunningReport', true)
      if (params.post) {
        await dispatch('postReport', params)
        return
      }
      await dispatch('getReport', params)
      commit('setIsRunningReport', false)
    },
    async getReport({ commit }, params) {
      await APIservice.GET(
        `Report/${params.reportAddress}?${params.data}`
      ).then((res) => {
        commit('setReportData', res.data)
      })
    },
    async postReport({ commit }, params) {
      await APIservice.POST(`Report/${params.reportAddress}`, params.data).then(
        (res) => {
          commit('setReportData', res.data)
        }
      )
    },
  },
  mutations: {
    setReports(state, reports) {
      state.reports = reports
    },
    setGroups(state, groups) {
      state.groups = groups
    },
    setDateRange(state, range) {
      state.dateRange = range
    },
    setReportData(state, data) {
      state.reportData = data
    },
    setIsRunningReport(state, isRunningReport) {
      state.isRunningReport = isRunningReport
    },
    clearState(state) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
}
