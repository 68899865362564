import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import userGroups from '@/shared/userRoles'

Vue.use(VueRouter)

const userRoleDefaultRoutes = {}
userRoleDefaultRoutes[userGroups.processor] = '/'
userRoleDefaultRoutes[userGroups.oliveSupport] = '/search'
userRoleDefaultRoutes[userGroups.customerServiceManager] = '/reports'

const canCreateSubmissions = [userGroups.processor, userGroups.oliveSupport]
const canRunReports = [userGroups.customerServiceManager]

const hasAccess = (allowedRoles) => {
  const roles = store.state.auth.roles ?? []
  for (let i = 0; i < allowedRoles.length; i++) {
    if (roles.includes(allowedRoles[i])) {
      return true
    }
  }
  return false
}

const nextIfAllowed = (to, next) => {
  const allowedRoles = to.meta.allowedUserRoles
  if (!allowedRoles) {
    // all user roles are allowed
    return next()
  }

  // Check for access to the restricted route
  if (hasAccess(allowedRoles)) {
    return next()
  }

  // The user does have access, redirect to default route
  const roles = store.state.auth.roles ?? []
  for (let i = 0; i < roles.length; i++) {
    const defaultRoute = userRoleDefaultRoutes[roles[i]]
    if (defaultRoute) {
      return next(defaultRoute)
    }
  }

  // The user does have access and does not have a role with a default route
  store.dispatch('RESET_GLOBAL_STATE')
  next(false)
}

const ifAuthenticated = (to, from, next) => {
  store.dispatch('auth/checkSessionToken').then(() => {
    if (store.state.auth.loggedIn) {
      if (!store.state.auth.authenticated) {
        return next('/twofactor')
      }

      if (store.getters.requiresOrganizationSelection) {
        return next('/selectorg')
      }

      if (!store.state.staticData.overview) {
        store.dispatch('staticData/getOverview')
      }

      if (hasAccess(canCreateSubmissions)) {
        if (!store.state.staticData.organizationProviderList) {
          store.dispatch('staticData/getOrganizationProviders')
        }

        if (!store.state.staticData.recentlyUsedProviders) {
          store.dispatch('staticData/getRecentProviders')
        }
      }

      store.dispatch('auth/refreshAuthToken')
      return nextIfAllowed(to, next)
    }
    store.dispatch('RESET_GLOBAL_STATE')
  })
}

const ifNotAuthenticated = (to, from, next) => {
  store.dispatch('auth/checkMaintenanceMode')
  store.dispatch('auth/checkSessionToken').then(() => {
    if (!store.state.auth.loggedIn) {
      return next()
    }
    next('/')
  })
}

const beforeTwoFactor = (to, from, next) => {
  store.dispatch('auth/checkSessionToken').then(() => {
    if (store.state.auth.loggedIn) {
      return next()
    }
    next('/signin')
  })
}

const beforeSelectOrganization = (to, from, next) => {
  store.dispatch('auth/checkSessionToken').then(() => {
    if (store.state.auth.authenticated) {
      if (!store.getters.requiresOrganizationSelection) {
        return next('/')
      }
      return next()
    }
    next('/signin')
  })
}

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PaRequestsList" */ '@/views/DashboardView.vue'
      ),
    meta: {
      allowedUserRoles: canCreateSubmissions,
    },
  },
  {
    path: '/submissions',
    name: 'ListPaRequest',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PaRequestsList" */ '@/views/ListPaRequestView.vue'
      ),
    meta: {
      allowedUserRoles: canCreateSubmissions,
    },
  },
  {
    path: '/archive',
    name: 'ArchivedPaRequests',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PaRequestsList" */ '@/views/ListArchiveView.vue'
      ),
    meta: {
      allowedUserRoles: canCreateSubmissions,
    },
  },
  {
    path: '/search',
    name: 'Search',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(/* webpackChunkName: "Search" */ '@/views/SearchView.vue'),
    meta: {
      allowedUserRoles: canCreateSubmissions,
    },
  },
  {
    path: '/signin',
    name: 'SignIn',
    beforeEnter: ifNotAuthenticated,
    component: () =>
      import(/* webpackChunkName: "Auth" */ '@/views/AuthSignInView.vue'),
  },
  {
    path: '/sso',
    name: 'SSO',
    beforeEnter: ifNotAuthenticated,
    component: () =>
      import(/* webpackChunkName: "Auth" */ '@/views/AuthSSO.vue'),
  },
  {
    path: '/twofactor',
    name: 'TwoFactor',
    beforeEnter: beforeTwoFactor,
    component: () =>
      import(/* webpackChunkName: "TwoFactor" */ '@/views/TwoFactorView.vue'),
  },
  {
    path: '/request/:oliveTrackingId',
    name: 'DetailPaRequest',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PaRequestDetail" */ '@/views/DetailPaRequestView.vue'
      ),
    props: ({ params }) => ({
      oliveTrackingId: params.oliveTrackingId || '0',
    }),
    meta: {
      allowedUserRoles: canCreateSubmissions,
    },
  },
  {
    path: '/member/lookup',
    name: 'MemberLookup',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "MemberLookup" */ '@/views/MemberLookupView.vue'
      ),
    meta: {
      allowedUserRoles: canCreateSubmissions,
    },
  },
  {
    path: '/request/edit/:oliveTrackingId',
    name: 'EditDraftPaRequest',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PaRequestCreate" */ '@/views/CreatePaRequestView.vue'
      ),
    props: ({ params }) => ({
      oliveTrackingId: params.oliveTrackingId || '0',
    }),
    meta: {
      allowedUserRoles: canCreateSubmissions,
    },
  },
  // {
  //   path: '/settings',
  //   name: 'Settings',
  //   beforeEnter: ifAuthenticated,
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "PaRequestCreate" */ '@/views/SettingsView.vue'
  //     ),
  // },
  {
    path: '/account',
    name: 'MyAccount',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(/* webpackChunkName: "MyAccount" */ '@/views/MyAccountView.vue'),
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    beforeEnter: ifNotAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ '@/views/ForgotPasswordView.vue'
      ),
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    beforeEnter: ifNotAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ '@/views/ResetPasswordView.vue'
      ),
    props: ({ query }) => ({
      token: decodeURIComponent(query.token).replace(/\s/g, '+'),
    }),
  },
  {
    path: '/selectorg',
    name: 'SelectOrganization',
    beforeEnter: beforeSelectOrganization,
    component: () =>
      import(
        /* webpackChunkName: "SelectOrganization" */ '@/views/SelectOrganizationView.vue'
      ),
  },
  {
    path: '/help',
    name: 'HelpCenter',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(/* webpackChunkName: "HelpCenter" */ '@/views/HelpCenterView.vue'),
  },
  {
    path: '/reports',
    name: 'Reports',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(/* webpackChunkName: "Reports" */ '@/views/ReportsView.vue'),
    meta: {
      allowedUserRoles: canRunReports,
    },
  },
  {
    path: '/report',
    name: 'Report',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(/* webpackChunkName: "Report" */ '@/views/ReportView.vue'),
    meta: {
      allowedUserRoles: canRunReports,
    },
  },
  {
    path: '/error',
    name: 'ErrorSplash',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PaRequestsList" */ '@/views/ErrorSplashView.vue'
      ),
  },
  {
    path: '/signout',
    name: 'SignOut',
    component: () =>
      import(
        /* webpackChunkName: "PaRequestsList" */ '@/views/AuthSignOutView.vue'
      ),
  },
  {
    path: '/*',
    name: 'Error404',
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "PaRequestsList" */ '@/views/Error404View.vue'
      ),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  store.dispatch('navigation/onNavigation', { from, to })
  next()
})

export default router
