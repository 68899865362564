/* 
  explicitly import babel polyfills for Kendo IE 11 support 
  https://www.telerik.com/kendo-vue-ui/components/wrappers-troubleshooting/#toc-i-would-like-to-enable-ie-11-support-for-my-vue-app
*/
import 'element-remove'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MFEloader from '@/shared/plugins/MFELoader'
import appInsightsLogger from '@/shared/plugins/appInsightsLogger'
import '@/shared/utilities.js'
import '@/shared/filters.js'
import '@/shared/directives.js'
import '@/css/global.scss'
import 'animate.css'

// Suggest component styles in create PA flow
import 'vue-simple-suggest/dist/styles.css'

Vue.config.productionTip = false

Vue.use(appInsightsLogger)
Vue.use(MFEloader)
console.log('This should NOT display in prod')

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
